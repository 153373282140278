import React from 'react'
import { CFView, CFImage, DefaultScreen, MobileScreen } from 'components'
import { mobilePromotion, promotions } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView column center mb="40px">
          <CFImage src={mobilePromotion} w="80%" alt="Promotions" />
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView column center maxWidth="1400px" m="30px auto" w="100%">
          <CFImage src={promotions} w="90%" alt="Promotions" />
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
